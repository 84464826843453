import { useEffect, useState, Fragment, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Dialog, Transition, Switch } from "@headlessui/react";
import { GetOneJob, AddFreightToAJob, UpdateJobFreight, RemoveJobFreight } from "../../actions/jobs";
import toast from "react-hot-toast";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { formatCurrency } from "../../components/tools";
import * as _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Cancel, EditPencil, Trash } from "iconoir-react";
import { DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";
import { Helmet } from "react-helmet-async";

const JobFreight = ({ authState, authDispatch }) => {
  let [loading, setLoading] = useState(true);
  let [dummyLoading, setDummyLoading] = useState(false);
  let [job, setJob] = useState({});
  // eslint-disable-next-line
  let [location, setLocation] = useState({});
  let [addFreightModal, setAddFreightModal] = useState(false);
  let [freightData, setFreightData] = useState({
    poNumber: "",
    freightCost: 0,
    freightMarkup: 0,
    freightChargeOut: 0,
    vendor: "",
    freightId: "",
  });
  let [editFreightModal, setEditFreightModal] = useState(false);

  const { jobId } = useParams();
  const navigate = useNavigate();

  const freightCostRef = useRef();
  const freightMarkupRef = useRef();
  const freightChargeOutRef = useRef();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOneJob(jobId)
        .then((res) => {
          setJob(res.data.job);
          setLocation(res.data.location);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
          setTimeout(() => {
            navigate("/jobs");
          }, 3000);
        });
    }
    return () => {
      inView = false;
    };
    // eslint-disable-next-line
  }, []);

  const tabs = [
    { name: "Info", href: "#", current: false },
    { name: "Parts", href: "parts", current: false },
    { name: "Labor", href: "labor", current: false },
    { name: "Parking", href: "parking", current: false },
    { name: "Loadbank", href: "loadbank", current: false },
    { name: "Freight", href: "freight", current: true },
    { name: "Misc", href: "misc", current: false },
    { name: "POs", href: "purchaseOrders", current: false },
    {
      name: "ITs",
      href: "inventoryTransmittals",
      current: false,
    },
    { name: "Equip.", href: "equipment", current: false },
    { name: "Tests", href: "tests", current: false },
    { name: "Warranty", href: "warranty", current: false },
    { name: "Payments", href: "payments", current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const closeAddFreightModal = () => {
    setAddFreightModal(false);
    setFreightData({
      poNumber: "",
      freightCost: 0,
      freightMarkup: 0,
      freightChargeOut: 0,
      vendor: "",
      freightId: "",
    });
  };

  const addNewFreight = () => {
    let failed = false;
    if (freightData.poNumber === "") {
      toast.error("Please enter a PO Number");
      failed = true;
    } else if (freightData.vendor === "") {
      toast.error("Please enter a vendor");
      failed = true;
    } else if (freightData.freightCost === 0) {
      toast.error("Please enter a freight cost");
      failed = true;
    } else if (freightData.freightChargeOut === 0) {
      toast.error("Please enter a freight charge out");
      failed = true;
    }
    if (!failed) {
      setLoading(true);
      AddFreightToAJob(jobId, freightData)
        .then((res) => {
          toast.success("Freight fee added successfully");
          closeAddFreightModal();
          GetOneJob(jobId)
            .then((res) => {
              setJob(res.data.job);
              setLocation(res.data.location);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
              setTimeout(() => {
                navigate("/jobs");
              }, 3000);
            });
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error adding freight fee to this job");
          setLoading(false);
        });
    }
  };

  const changeFreightData = (e) => {
    setDummyLoading(true);
    let tmp = freightData;
    let { id, value } = e.target;
    if (id === "freightCost" || id === "freightMarkup" || id === "freightChargeOut") {
      value = parseFloat(value);
    }
    tmp[id] = value;
    if (id === "freightCost") {
      if (tmp.freightMarkup === 0) {
        tmp.freightChargeOut = tmp.freightCost;
        freightChargeOutRef.current.value = tmp.freightChargeOut;
      } else {
        tmp.freightChargeOut = parseFloat((tmp.freightCost + tmp.freightCost * (tmp.freightMarkup / 100)).toFixed(2));
        freightChargeOutRef.current.value = tmp.freightChargeOut;
      }
    }
    if (id === "freightMarkup") {
      if (tmp.freightCost !== 0) {
        tmp.freightChargeOut = parseFloat((tmp.freightCost + tmp.freightCost * (tmp.freightMarkup / 100)).toFixed(2));
        freightChargeOutRef.current.value = tmp.freightChargeOut;
      }
    }
    if (id === "freightChargeOut") {
      if (tmp.freightCost !== 0) {
        tmp.freightMarkup = parseFloat((((tmp.freightChargeOut - tmp.freightCost) / tmp.freightCost) * 100).toFixed(2));
        freightMarkupRef.current.value = tmp.freightMarkup;
      }
    }
    setFreightData(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const renderAddFreightModal = () => {
    return (
      <Transition.Root show={addFreightModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeAddFreightModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:px-6">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Add Freight Fee
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 py-2 mt-2">
                        <div key="poNumber" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="poNumber" className="pb-1 text-xs text-gray-600 uppercase">
                            PO Number
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="poNumber"
                            onChange={changeFreightData}
                          />
                        </div>
                        <div key="vendor" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="vendor" className="pb-1 text-xs text-gray-600 uppercase">
                            Vendor
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="vendor"
                            onChange={changeFreightData}
                          />
                        </div>
                        <div key="freightCost" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="freightCost" className="pb-1 text-xs text-gray-600 uppercase">
                            Freight Cost
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="freightCost"
                            ref={freightCostRef}
                            onChange={changeFreightData}
                          />
                        </div>
                        <div key="freightMarkup" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="freightMarkup" className="pb-1 text-xs text-gray-600 uppercase">
                            Freight Markup
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="freightMarkup"
                            ref={freightMarkupRef}
                            onChange={changeFreightData}
                          />
                        </div>
                        <div key="freightChargeOut" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="freightChargeOut" className="pb-1 text-xs text-gray-600 uppercase">
                            Freight Charge Out
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="freightChargeOut"
                            ref={freightChargeOutRef}
                            onChange={changeFreightData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closeAddFreightModal()} />
                    <PrimaryButton label="Add Freight Fee" callback={() => addNewFreight()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const renderFreightLine = (line, index) => {
    return (
      <div className="grid items-center w-full grid-cols-3 gap-4 py-3 border-b border-gray-300 md:grid-cols-7" key={uuidv4()}>
        <p className="hidden capitalize md:inline-flex">#{index + 1}</p>
        <p className="truncate">{line.vendor}</p>
        <p className="hidden capitalize md:inline-flex">{line.poNumber.length > 0 ? line.poNumber : "Not Provided"}</p>
        <p className="hidden md:inline-flex">{formatCurrency(line.freightCost)}</p>
        <p className="hidden md:inline-flex">{line.freightMarkup}%</p>
        <p>{formatCurrency(line.freightChargeOut)}</p>
        {job.jobStatus === "open" && (
          <div className="flex flex-row items-center justify-center gap-2">
            <SecondaryButton label="Remove" callback={() => submitFreightRemove(line.freightId)} />
            <SecondaryButton label="Edit" callback={() => openFreightEdit(line.freightId)} />
          </div>
        )}
      </div>
    );
  };

  const openFreightEdit = (id) => {
    let frt = _.find(job.freight, { freightId: id });
    if (frt) {
      setFreightData(frt);
      setTimeout(() => setEditFreightModal(true), 700);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  const closeFreightEdit = () => {
    setEditFreightModal(false);
    setFreightData({
      poNumber: "",
      freightCost: 0,
      freightMarkup: 0,
      freightChargeOut: 0,
      vendor: "",
      freightId: "",
    });
  };

  const submitFreightEdit = () => {
    setLoading(true);
    UpdateJobFreight(jobId, freightData.freightId, freightData)
      .then((res) => {
        toast.success("Freight fee updated successfully");
        closeFreightEdit();
        GetOneJob(jobId)
          .then((res) => {
            setJob(res.data.job);
            setLocation(res.data.location);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
            setTimeout(() => {
              navigate("/jobs");
            }, 3000);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error updating loadbank fee");
        setLoading(false);
      });
  };

  const renderFreightEditModal = () => {
    return (
      <Transition.Root show={editFreightModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeFreightEdit}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:px-6">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Edit Freight Fee
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 py-2 mt-2">
                        <div key="poNumber" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="poNumber" className="pb-1 text-xs text-gray-600 uppercase">
                            PO Number
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="poNumber"
                            defaultValue={freightData.poNumber}
                            onChange={changeFreightData}
                          />
                        </div>
                        <div key="vendor" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="vendor" className="pb-1 text-xs text-gray-600 uppercase">
                            Vendor
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="vendor"
                            defaultValue={freightData.vendor}
                            onChange={changeFreightData}
                          />
                        </div>
                        <div key="freightCost" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="freightCost" className="pb-1 text-xs text-gray-600 uppercase">
                            Freight Cost
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="freightCost"
                            ref={freightCostRef}
                            defaultValue={freightData.freightCost}
                            onChange={changeFreightData}
                          />
                        </div>
                        <div key="freightMarkup" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="freightMarkup" className="pb-1 text-xs text-gray-600 uppercase">
                            Freight Markup
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="freightMarkup"
                            ref={freightMarkupRef}
                            defaultValue={freightData.freightMarkup}
                            onChange={changeFreightData}
                          />
                        </div>
                        <div key="freightChargeOut" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="freightChargeOut" className="pb-1 text-xs text-gray-600 uppercase">
                            Freight Charge Out
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="freightChargeOut"
                            ref={freightChargeOutRef}
                            defaultValue={freightData.freightChargeOut}
                            onChange={changeFreightData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closeFreightEdit()} />
                    <PrimaryButton label="Save" callback={() => submitFreightEdit()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const submitFreightRemove = (freightId) => {
    setLoading(true);
    RemoveJobFreight(jobId, freightId)
      .then((res) => {
        toast.success("Freight fee removed successfully");
        GetOneJob(jobId)
          .then((res) => {
            setJob(res.data.job);
            setLocation(res.data.location);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
            setTimeout(() => {
              navigate("/jobs");
            }, 3000);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error removing freight fee");
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>Freight - {job.jobNo} | HTPS ERP</title>
          </Helmet>
          <div className="flex flex-row items-center justify-start w-full mb-3">
            <div className="w-full sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:border-blue-700 focus:outline-none focus:ring-blue-700 sm:text-sm"
                defaultValue={tabs.find((tab) => tab.current).href}
                onChange={(v) => navigate(`/jobs/${jobId}/${v.target.value}`)}
              >
                {tabs.map((tab) => (
                  <option value={tab.href}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden w-full sm:block">
              <div className="border-b border-gray-200">
                <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <p
                      value={tab.name}
                      key={uuidv4()}
                      onClick={() => navigate(`/jobs/${jobId}/${tab.href}`)}
                      className={classNames(
                        tab.current ? "border-blue-700 text-blue-700" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer",
                      )}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name}
                    </p>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-start w-full px-1 mt-1">
            <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
              <p className="text-xl font-bold uppercase">Job Freight Charges</p>
              {job.jobStatus === "open" && <SecondaryButton label="Add a Freight Charge" callback={() => setAddFreightModal(true)} />}
            </div>
            <div className="grid w-full grid-cols-3 gap-4 py-3 border-b border-gray-300 md:grid-cols-7">
              <p className="hidden text-xs font-semibold uppercase md:inline-flex"></p>
              <p className="text-xs font-semibold uppercase">Vendor</p>
              <p className="hidden text-xs font-semibold uppercase md:inline-flex">PO Number</p>
              <p className="hidden text-xs font-semibold uppercase md:inline-flex">Cost</p>
              <p className="hidden text-xs font-semibold uppercase md:inline-flex">Markup</p>
              <p className="text-xs font-semibold uppercase">Charge Out</p>
              <div className="flex flex-row items-center justify-center"></div>
            </div>
            {job.freight.map((line, index) => renderFreightLine(line, index))}
          </div>
          {renderAddFreightModal()}
          {renderFreightEditModal()}
        </>
      )}
    </div>
  );
};

export default JobFreight;
