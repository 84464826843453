import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as _ from "lodash";
import { OpenInWindow, Printer } from "iconoir-react";
import { GetARAgingReport, GetCollectedSalesTax } from "../../../actions/reports";
import dayjs from "dayjs";
import { formatCurrency } from "../../../components/tools";
import { GenerateARAgingReport } from "../../../data/pdf";
import { Button, Input, Select, Table } from "antd";
import { Helmet } from "react-helmet-async";
import { render } from "@testing-library/react";

const SalesTaxDepositReport = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [rawInvoices, setRawInvoices] = useState([]);
  const [monthOptions, setMonthOptions] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(null);
  const [taxData, setTaxData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;

    if (inView) {
      const months = generateMonthOptions();
      setMonthOptions(months);
      GetCollectedSalesTax({ month: dayjs().subtract(1, "month").toJSON() })
        .then((res) => {
          processData(res.data);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "We encountered an error while loading the report");
          setLoading(false);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const generateMonthOptions = () => {
    const startDate = dayjs("2023-01-01");
    const currentDate = dayjs();
    const months = [];

    let date = startDate;
    while (date.isBefore(currentDate) || date.isSame(currentDate, "month")) {
      months.push({
        value: date.startOf("month").toISOString(),
        label: date.format("MMMM, YYYY"),
      });
      date = date.add(1, "month");
      if (date.isSame(currentDate, "month")) {
        setCurrentMonth(date.subtract(1, "month").startOf("month").toISOString());
      }
    }

    return months;
  };

  const processData = (result) => {
    const final = [];
    result.data.forEach((r) => {
      if (r?.invoiceId?.length > 0) {
        r.payments.forEach((p) => {
          final.push({
            date: dayjs(p.paymentDate).format("MM/DD/YYYY"),
            number: r.invoiceNo,
            customerName: r.customerCode + " | " + r.customerName,
            payment: p.paymentAmount,
            exempt: parseFloat((parseFloat(r.total.toFixed(2)) - parseFloat(r.totalTaxableAmount.toFixed(2))).toFixed(2)),
            taxable: parseFloat(r.totalTaxableAmount.toFixed(2)),
            salesTax: parseFloat(r.tax.toFixed(2)),
            id: `/invoices/${r.invoiceId}`,
          });
        });
      } else if (r?.jobId?.length > 0) {
        r.payments.forEach((p) => {
          final.push({
            date: dayjs(p.date).format("MM/DD/YYYY"),
            number: r.jobNo,
            customerName: r.customerCode + " | " + r.customerName,
            payment: p.amount,
            exempt: parseFloat((parseFloat(r.total.toFixed(2)) - parseFloat(r.totalTaxableAmount.toFixed(2))).toFixed(2)),
            taxable: parseFloat(r.totalTaxableAmount.toFixed(2)),
            salesTax: parseFloat(r.taxTotal.toFixed(2)),
            id: `/jobs/${r.jobId}`,
          });
        });
      }
    });
    console.log("FINAL:", final);
    setTaxData(final);
    setTimeout(() => setLoading(false), 700);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Job / Invoice",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Payment Amount",
      dataIndex: "payment",
      render: (r) => <p className="text-right">{formatCurrency(r)}</p>,
      align: "right",
    },
    {
      title: "Exempt",
      dataIndex: "exempt",
      key: "exempt",
      render: (r) => <p className="text-right">{formatCurrency(r)}</p>,
      align: "right",
    },
    {
      title: "Taxable",
      dataIndex: "taxable",
      key: "taxable",
      render: (r) => <p className="text-right">{formatCurrency(r)}</p>,
      align: "right",
    },
    {
      title: "Sales Tax",
      dataIndex: "salesTax",
      key: "salesTax",
      render: (r) => <p className="text-right">{formatCurrency(r)}</p>,
      align: "right",
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (r) => (
        <div className="flex items-center justify-end w-full">
          <Button onClick={() => window.open(r, "_blank")}>View</Button>
        </div>
      ),
    },
  ];

  const monthSwitch = (e) => {
    console.log("MONTH:", e);
    setCurrentMonth(e);
    setLoading(true);
    GetCollectedSalesTax({ month: e })
      .then((res) => {
        processData(res.data);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.response.data ? err.response.data.message : "We encountered an error while loading the report");
        setLoading(false);
      });
  };

  const printReport = () => {
    //
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      <Helmet>
        <title>Sales Tax Deposit Report | HTPS ERP</title>
      </Helmet>
      <div className="flex flex-col items-start justify-start w-full h-full gap-2 px-1 mt-1">
        <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
          <div className="flex flex-col items-start justify-center gap-1">
            <p className="text-xl font-bold uppercase">Sales Tax Deposit Report</p>
            <p className="text-sm text-gray-600/80">A detailed report of all sales tax collected, broken down by amounts, month and location.</p>
          </div>
          <div className="flex items-center justify-end gap-2 w-fit">
            <Select
              placeholder="Pick a month"
              options={monthOptions}
              onChange={(e) => monthSwitch(e)}
              className="w-[240px] font-sans mb-1.5"
              value={currentMonth}
              loading={loading}
            />
            <button
              onClick={() => printReport()}
              className="py-1.5 px-4 flex justify-center items-center gap-2 rounded-md border-2 border-gray-200 font-semibold text-gray-500 hover:text-white hover:bg-black hover:border-black focus:outline-none focus:ring-0 transition-all text-sm whitespace-nowrap"
            >
              <Printer className="w-5 h-5" />
              Print Out
            </button>
          </div>
        </div>
        {!loading ? (
          <>
            <Table
              columns={columns}
              dataSource={taxData}
              size="small"
              className="w-full"
              loading={loading}
              rowKey={(r) => r.number}
              bordered
              pagination={false}
            />
          </>
        ) : (
          <div className="flex flex-col items-center justify-center w-full h-full gap-1">
            <p className="text-sm uppercase text-gray-500/90">Loading</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SalesTaxDepositReport;
